<template>
  <right-content>

    <el-button @click="exportReports" class="btn" type="primary">导出</el-button>

    <my-table :tableInfo="tableInfo" :colConfigs="colConfigs" @currentPage="currentPage">
      <el-table-column align="center" slot="option" label="操作">
        <template slot-scope="{row}">
          <el-button
            icon="iconfont icon-chakan-copy1"
            @click="checkStatus(row)"
          >查看处理
          </el-button>
        </template>
      </el-table-column>
    </my-table>

  </right-content>
</template>

<script>
import {exportListReports, reportList } from '@/api'
import MyTable from '@/components/MyTable'
import RightContent from '../commonViews/RightContent.vue'
import { exportFun } from '@/utils/params'

export default {
  name: 'list-ting',
  components: {
    MyTable,
    RightContent
  },
  async created () {
    await this.renderTable()
  },
  async activated () {
    await this.renderTable()
  },
  data () {
    return {
      tableInfo: {},
      requestParams: {},
      selectValue: '',
      colConfigs: [
        {
          prop: 'id',
          label: '序号',
          width: '50px'
        },
        {
          prop: 'created_at',
          label: '报事时间'
        },
        {
          prop: 'user_info.name',
          label: '报事人'
        },
        {
          prop: 'sentry_info.name',
          label: '所在监督岗'
        },
        {
          prop: 'type',
          label: '报事处理状态'
        },
        { slot: 'option' },
      ],
    }
  },
  methods: {
    currentPage (current) {
      this.page = current
      this.renderTable()
    },
    exportReports () {
      const param = {
        type: 3,
        department_id: localStorage.getItem('department_id') || '',
        company_id: localStorage.getItem('company_id') || '',
        group_id: localStorage.getItem('group_id') || '',
      }
      exportFun(exportListReports, param, 'exportListReports')
    },
    async renderTable () {
      const res = await reportList({
        ...this.requestParams,
        page: this.page
      })
      this.tableInfo = res.data.data
    },
    async submit () {
      const requestData = {
        department_id: this.selectValue[0],
        sentry_id: this.selectValue[1] || '',
        page: 1
      }
      this.requestParams =
      await this.renderTable(requestData)
    },
    getSelectVal (val) {
      this.selectValue = val
    },
    checkStatus ({
      user_info,
      id
    }) {
      this.$router.push({
        path: '/list/list-detail',
        query: {
          name: user_info.name,
          id
        },
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
  width: 15%;
  background-color: red;
  color: #fff;
  padding: 10px;
  margin-bottom: 20px;
}
.select {
  display: flex;
  margin-top: -20px;
  margin-bottom: 10px;
  ::v-deep .el-select {
    margin-right: 10px;
  }

  button {
    background-color: red;
    color: #fff;
    padding: 0 20px;
    margin-left: 30px;
  }
}

//.table{
//  margin-top: 150px;
//}
button {
  padding: 7px 25px;
  background: #fde5e4;
  border-radius: 4px;
  border: 0;
  font-size: 15px;
  font-family: PingFang SC;
  color: #e32322;
}

</style>
